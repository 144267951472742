import React from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Typography, message } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import RegenerateTokenModal from './RegenerateTokenModal';
import { regenerateApiToken } from '../../../actions/prerenderUserActions';
import { useEvent } from '../../events/hooks/useEvent';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

function RegenerateToken() {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { track } = useEvent();

  const onCancel = () => {
    setModalVisible(false);
    setError(null);
  };

  const onConfirm = async () => {
    try {
      await regenerateApiToken(dispatch);
      setModalVisible(false);
      messageApi.success('Token regenerated successfully!');
      track('Settings Updated', {
        token_is_updated: true,
        recaching_status_is_updated: false,
        rendering_timeout_is_updated: false,
        rendering_location_is_updated: false,
        https_status_is_updated: false,
      });
    } catch (errorMsg) {
      setError(errorMsg);
    }
    setIsLoading(false);
  };

  return (
    <>
      {messageContext}
      <RegenerateTokenModal
        open={modalVisible}
        onCancel={onCancel}
        onConfirm={onConfirm}
        error={error}
        isLoading={isLoading}
      />
      <Flex vertical gap="large">
        <div>
          <Typography.Paragraph>
            <WarningFilled /> Warning! Generating a new token will invalidate the current one. In case your integration
            is already connected, this will break it until you replace the token in the authentication flow.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Only use this if your current token is exposed and needs to be replaced.
          </Typography.Paragraph>
        </div>
        <Flex justify="center">
          <ButtonWithRole
            disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
            type="primary"
            danger
            onClick={() => setModalVisible(true)}
          >
            Generate new token
          </ButtonWithRole>
        </Flex>
      </Flex>
    </>
  );
}

export default RegenerateToken;
