import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Typography } from 'antd';

import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const Error404Page = () => {
  return (
    <AdminTemplate>
      <Flex align="center" justify="center" gap="large">
        <div>
          <Typography.Title>Page not found</Typography.Title>
          <Typography.Paragraph>
            It looks like the page you are looking for does not exist. Please check the URL or click the button below to
            navigate to the dashboard.
          </Typography.Paragraph>
          <Link to="/" component={(props) => <Button {...props} type="primary" />}>
            Return to the Dashboard
          </Link>
        </div>
        <img style={{ maxHeight: '300px' }} src="/assets/img/not_found.svg" alt="404 Not Found" />
      </Flex>
    </AdminTemplate>
  );
};

export default requireAuth(Error404Page);
