import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import RenderingLocationCard from './renderingLocationCard';
import { setRenderRegion } from '../redux/settingsActions';

const SHARED = 'shared';
const US = 'us';
const EU = 'eu';

const RenderingLocationsCard = ({ cm, settings, setRenderRegion: doSetRenderRegion }) => {
  const navigate = useNavigate();
  const isOnShared = settings.renderLocation.renderRegion === SHARED;
  const isOnUS = settings.renderLocation.renderRegion === US;
  const isOnEU = settings.renderLocation.renderRegion === EU;

  const sharedButtonText = isOnShared ? cm.current : cm.select;
  const usButtonText = settings.renderLocation.changeAvailable ? (isOnUS ? cm.current : cm.select) : cm.upgrade;

  const euButtonText = settings.renderLocation.changeAvailable ? (isOnEU ? cm.current : cm.select) : cm.upgrade;

  const badgeText = settings.renderLocation.changeAvailable ? null : cm.badgeText;

  const onSharedSelected = () => {
    doSetRenderRegion(SHARED);
  };

  const onUSSelected = () => {
    if (settings.renderLocation.changeAvailable) {
      doSetRenderRegion(US);
    } else {
      navigate('/billing/plans');
    }
  };

  const onEUSelected = () => {
    if (settings.renderLocation.changeAvailable) {
      doSetRenderRegion(EU);
    } else {
      navigate('/billing/plans');
    }
  };

  return (
    <Card title={cm.title}>
      <div className="col">
        {cm.descriptions.map((description, i) => (
          <div className="row" key={i}>
            <p>{description.map(createHtmlContentFromCmText)}</p>
          </div>
        ))}
        <div className="row">
          <div className="col">
            <RenderingLocationCard
              current={isOnShared}
              icon={'fe-globe'}
              title={cm.shared.title}
              buttonText={sharedButtonText}
              onClick={onSharedSelected}
            />
          </div>
          <div className="col">
            <RenderingLocationCard
              current={isOnUS}
              disabled={settings.inProgress}
              icon={'fe-map-pin'}
              title={cm.us.title}
              buttonText={usButtonText}
              onClick={onUSSelected}
              badgeText={badgeText}
            />
          </div>
          <div className="col">
            <RenderingLocationCard
              current={isOnEU}
              disabled={settings.inProgress}
              icon={'fe-map-pin'}
              title={cm.eu.title}
              buttonText={euButtonText}
              onClick={onEUSelected}
              badgeText={badgeText}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      title: 'Rendering Location',
      current: 'Current',
      select: 'Select',
      upgrade: 'Upgrade Plan',
      badgeText: 'Scaleup+',
      descriptions: [
        [
          {
            type: 'text',
            text: 'With this option you can set where the render should happen.',
          },
        ],
      ],
      shared: {
        title: 'Global Shared',
      },
      us: {
        title: 'United States',
      },
      eu: {
        title: 'Europe',
      },
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setRenderRegion }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderingLocationsCard);
