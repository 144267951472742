// imported by /reducers/index.js
// holds road search parameters and result

import {
  PRERENDER_USER_CLEAR,
  PRERENDER_USER_IN_PROGRESS,
  PRERENDER_USER_SET,
  PRERENDER_USER_UNAUTHORIZED,
  SET_SHOW_ONBOARDING,
  UPDATE_PRERENDER_USER_TRACKING_CODE,
  UPDATE_PRERENDER_TOKEN,
} from '../actions/types';

import { stringToDate } from '../assets/dateFormatter';

const emptyState = {
  id: null,
  email: '',
  numPagesCached: 0,
  token: '',
  signupDate: null,
  trackingCodeInstalled: false,
  cacheFreshness: null,
  hasBillingInfo: false,
  billingType: 'None',
  recachingDisabled: false,
  chargebeePlanId: null,
  planName: 'Free',
  costPerMonth: 0,
  accountType: null,
  emailVerified: false,
  customPrice: null,
  customCachedPagesLimit: null,
  recacheDelay: null,
  invoiceCustomText: null,
  invoiceEmail: null,
  userPausedRecaching: false,
  cloudUser: false,
  queryParamsWhitelist: false,
  ignoreAllQueryParams: false,
  chargingThroughChargebee: true,
  hasBillingAddress: false,
  firstName: null,
  lastName: null,
  cancelledAt: null,
  plan: {
    estimate: {},
  },
  isMeteredBilling: false,
  settings: {},
  features: [],
  companyName: null,
  adminEmail: null,

  inProgress: false,
  hasSession: false,

  showOnboarding: false,
  isCacheFreshnessDisabled: true,
  nextBillingAt: null,
  showRegistrationSteps: false,
  trackingCodeInstalledDate: null,
  csmId: null,
  role: null,
};

const initialState = emptyState;

export default function prerenderUserReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case PRERENDER_USER_SET: {
      const {
        id,
        email,
        numPagesCached,
        token,
        signupDate,
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        billingType,
        recachingDisabled,
        planName,
        costPerMonth,
        accountType,
        emailVerified,
        customPrice,
        customCachedPagesLimit,
        recacheDelay,
        invoiceCustomText,
        invoiceEmail,
        userPausedRecaching,
        cloudUser,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        chargingThroughChargebee,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        hasCustomContractPricing,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        nextBillingAt,
        lastCharged,
        customRecacheDelay,
        chargeBeePlanId,
        showRegistrationSteps,
        trackingCodeInstalledDate,
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
      } = payload;

      return {
        ...state,
        id,
        email,
        numPagesCached,
        token,
        signupDate: stringToDate(signupDate),
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        billingType,
        recachingDisabled,
        planName,
        costPerMonth,
        accountType,
        emailVerified,
        customPrice,
        hasCustomContractPricing,
        customCachedPagesLimit,
        recacheDelay,
        invoiceCustomText,
        invoiceEmail,
        userPausedRecaching,
        cloudUser,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        chargingThroughChargebee,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        customRecacheDelay,
        chargebeePlanId: chargeBeePlanId,

        inProgress: false,

        hasSession: true,

        isCancelled: cancelledAt && recachingDisabled,
        nextBillingAt: stringToDate(nextBillingAt),
        lastCharged: stringToDate(lastCharged),

        upgradedFromChargebeePlanId: payload?.upgradedFromChargebeePlanId,
        upgradedAt: stringToDate(payload?.upgradedAt),
        showRegistrationSteps,
        trackingCodeInstalledDate: stringToDate(trackingCodeInstalledDate),
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
      };
    }

    case UPDATE_PRERENDER_TOKEN:
      return { ...state, token: payload };

    case PRERENDER_USER_IN_PROGRESS:
      return { ...state, inProgress: payload.value };

    case PRERENDER_USER_UNAUTHORIZED:
      return { ...state, hasSession: false };
    case UPDATE_PRERENDER_USER_TRACKING_CODE:
      return { ...state, trackingCodeInstalled: payload };
    case SET_SHOW_ONBOARDING:
      return {
        ...state,
        showOnboarding: payload,
      };
    case PRERENDER_USER_CLEAR:
      return emptyState;
    default:
  }
  return state;
}
