import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Card, Input, Typography, Flex, Row, Col, theme } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { revokeInvitation } from '../../../actions/prerenderUserActions';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import useKeycloakRealm from '../../../hooks/useKeycloakRealm';
import { useDeleteUserMutation } from '../../teams/teamApiSliceLegacy';
import { AppUserRoles, UpdateRoleModal, InvitationForm } from '../../teams';
import {
  useInviteUserMutation,
  useGetInvitedLoginUsersQuery,
  useUpdateLoginUserRoleMutation,
} from '../../api/appUserApiSlice';
import { datetimeDisplayFormat } from '../../../utils/datetimeDisplayFormat';
import { useIsDisabledForRole } from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { getLowerTierRoles } from '../../teams/appUserRoles';
import UserActions from '../../teams/UserActions';
import RolesDescriptions from '../../teams/RolesDescriptions';

const { Paragraph, Title } = Typography;

const TeamMembersPage = () => {
  const { token } = theme.useToken();
  const [searchTerm, setSearchTerm] = useState('');
  const [isRevokingInvite, setIsRevokingInvite] = useState(false);
  const [userBeingUpdated, setUserBeingUpdated] = useState(null);
  const prerenderUser = usePrerenderUser();
  const dispatch = useDispatch();
  const kcRealm = useKeycloakRealm();
  const invitationDisabled = useIsDisabledForRole(USER_ROLES.GUEST);

  const shouldDisplayRoles = ['prerender', 'prerender-staging'].includes(kcRealm);

  const [deleteUser, deleteUserStatus] = useDeleteUserMutation();
  const [inviteUser, inviteUserStatus] = useInviteUserMutation();
  const [updateUserRole, updateUserRoleStatus] = useUpdateLoginUserRoleMutation();
  const {
    data: invitedLoginUsers = [],
    isFetching: isFetchingInvitedUsers,
    refetch: listInvitedUsers,
  } = useGetInvitedLoginUsersQuery('teams-page', { refetchOnMountOrArgChange: true });

  const doRevokeInvitationAndRefresh = (email) => {
    setIsRevokingInvite(true);
    dispatch(revokeInvitation(email))
      .then(listInvitedUsers)
      .catch((e) => console.error(e))
      .finally(() => setIsRevokingInvite(false));
  };

  const doDeleteUserAndRefresh = (email) => {
    deleteUser(email)
      .then(listInvitedUsers)
      .catch((e) => console.error(e));
  };

  const invitedUsersData = useMemo(
    () =>
      invitedLoginUsers
        .filter((item) => item.email.includes(searchTerm.toLowerCase()))
        .map((user) => ({
          ...user,
          invitedAtFormatted: user.invitedAt ? datetimeDisplayFormat.format(new Date(+user.invitedAt)) : '',
          joinedAtFormatted:
            user.joinedAt && user.status !== 'pending' ? datetimeDisplayFormat.format(new Date(+user.joinedAt)) : '',
        })),
    [invitedLoginUsers, searchTerm]
  );

  const onSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const onUpdateUserRole = (data) => {
    updateUserRole(data)
      .then(() => setUserBeingUpdated(null))
      .catch((e) => console.error(e));
  };

  const inviteUserResult = useMemo(() => {
    if (inviteUserStatus.isError) return inviteUserStatus.error;

    if (inviteUserStatus.isSuccess) return { email: 'success' };

    return {};
  }, [inviteUserStatus.isError, inviteUserStatus.isSuccess, inviteUserStatus.error]);

  const isTableLoading = isFetchingInvitedUsers || deleteUserStatus.isLoading;

  const lowerTierRoles = React.useMemo(() => getLowerTierRoles(prerenderUser.role), [prerenderUser.role]);
  const getCanEditUser = (invitedUserRole) => lowerTierRoles.some(({ value }) => value === invitedUserRole);

  return (
    <>
      <UpdateRoleModal
        open={!!userBeingUpdated}
        userBeingUpdated={userBeingUpdated}
        onClose={() => setUserBeingUpdated(null)}
        onSubmit={onUpdateUserRole}
        isLoading={updateUserRoleStatus.isLoading}
        userRole={prerenderUser.role}
      />
      <Title level={5}>Team Members</Title>
      <Paragraph type="secondary">
        Manage multiple accounts within your application, you can invite your team...
      </Paragraph>

      <Row gutter={[12, 24]}>
        <Col xs={24} xxl={20}>
          <Card size="small">
            <Flex vertical gap="small">
              <Input
                placeholder="Please enter the email address"
                allowClear
                onChange={onSearchChange}
                onPressEnter={onSearchChange}
                value={searchTerm}
                prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              />
              <Table
                bordered
                dataSource={invitedUsersData}
                rowKey="id"
                pagination={false}
                scroll={{ x: true }}
                size="small"
                loading={isTableLoading}
              >
                <Table.Column
                  title="Email Address"
                  dataIndex="email"
                  key="email"
                  render={(_text, record) => {
                    return (
                      <span>
                        {record.email}
                        {record.email === prerenderUser.email ? ' (you)' : ''}
                      </span>
                    );
                  }}
                />
                <Table.Column title="Status" dataIndex="status" key="status" />
                {shouldDisplayRoles && (
                  <Table.Column
                    title="Role"
                    dataIndex="role"
                    key="role"
                    className="col-min"
                    render={(role) => AppUserRoles[role]}
                  />
                )}
                <Table.Column
                  title="Invited At"
                  dataIndex="invitedAtFormatted"
                  key="invitedAtFormatted"
                  className="col-min"
                />
                <Table.Column
                  title="Joined At"
                  dataIndex="joinedAtFormatted"
                  key="joinedAtFormatted"
                  className="col-min"
                />
                <Table.Column
                  title="Actions"
                  key="Actions"
                  render={(_text, record) => {
                    if (record.email === prerenderUser.email) return null;

                    const isPending = record.status === 'pending';
                    const canEditUser = getCanEditUser(record.role);
                    const hasAdminRole =
                      prerenderUser.role === USER_ROLES.SUPER_ADMIN || prerenderUser.role === USER_ROLES.ADMIN;
                    return (
                      <UserActions
                        onResendInviteClick={
                          canEditUser && isPending ? () => inviteUser({ email: record.email }) : null
                        }
                        onUpdateUserClick={hasAdminRole && canEditUser ? () => setUserBeingUpdated(record) : null}
                        onRevokeInvitationClick={
                          hasAdminRole && canEditUser && isPending
                            ? () => doRevokeInvitationAndRefresh(record.email)
                            : null
                        }
                        revokeInvitationLoading={isRevokingInvite}
                        onDeleteUserClick={
                          hasAdminRole && canEditUser && !isPending ? () => doDeleteUserAndRefresh(record.email) : null
                        }
                      />
                    );
                  }}
                />
              </Table>
            </Flex>
          </Card>
        </Col>
        {!invitationDisabled && (
          <Col xs={24} xl={10}>
            <Card title="Invite Colleagues">
              <InvitationForm
                isLoading={inviteUserStatus.isLoading}
                invitation={inviteUserResult}
                onSubmit={inviteUser}
                selectRoles={shouldDisplayRoles}
                userRole={prerenderUser.role}
              />
            </Card>
          </Col>
        )}
        <Col xs={24} xl={14}>
          <Card title="Role Descriptions" styles={{ body: { padding: token.paddingSM } }}>
            <RolesDescriptions />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TeamMembersPage;
