import React, { useEffect, useMemo, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import requireAuth from '../../components/hocs/requireAuth';
import { Flex, Dropdown, Typography, Space, Button, Table, message, Card } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AdminTemplate from '../../layout/AdminTemplate';
import { useGetAddedDomainsQuery } from './api/domainsApiSlice';
import dayjs from 'dayjs';

const { Title } = Typography;

const PAGE_SIZE = 100;

const tableColumns = [
  { title: 'Domain', dataIndex: 'domain', key: 'domain' },
  { title: 'Date added (UTC)', dataIndex: 'created_at', key: 'createdAt' },
];

const dropdownItems = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
];

const NewlyAddedDomainsPage = () => {
  const [days, setTimeFilterValueInDays] = useState(1);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
  });
  const [totalDomains, setTotalDomains] = useState(0);
  const [addedDomains, setAddedDomains] = useState([]);
  const { data, error, isFetching } = useGetAddedDomainsQuery(
    useMemo(() => {
      return {
        from: dayjs().subtract(days, 'days').toISOString(),
        page: tableParams.pagination.current,
        pageSize: tableParams.pagination.pageSize,
      };
    }, [days, tableParams.pagination.current, tableParams.pagination.pageSize]),
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      setAddedDomains(
        data.data.map((item, index) => {
          return { domain: item.domain, created_at: dayjs(item.created_at).format('YYYY-MM-DD HH:mm') };
        })
      );
      setTotalDomains(data.totalCount);
    } else if (error) {
      message.error('Failed to fetch added domains');
    }
  }, [data, error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ ...tableParams, pagination });
  };

  const handleDropdownChange = (key) => {
    setTimeFilterValueInDays(Number(key));
    setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, current: 1 } });
  };

  return (
    <AdminTemplate>
      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={3}>Added Domains</Title>
            <Dropdown
              placement="bottom"
              arrow
              menu={{
                selectable: true,
                items: dropdownItems,
                defaultSelectedKeys: [`${days}`],
                onSelect: ({ key }) => handleDropdownChange(key),
              }}
            >
              <Button>
                <Space>
                  {days !== 1 ? `Last ${days} days` : `Last ${days} day`}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Flex>
          <Card>
            <Table
              loading={isFetching}
              columns={tableColumns}
              onChange={handleTableChange}
              pagination={{
                total: totalDomains,
                pageSize: PAGE_SIZE,
                current: tableParams.pagination.current,
                showSizeChanger: false,
              }}
              dataSource={addedDomains}
              bordered
            />
          </Card>
        </Flex>
      </Content>
    </AdminTemplate>
  );
};

export default requireAuth(NewlyAddedDomainsPage);
