import { restApi } from '../../api/restApiSlice';

const domainStatisticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainStatistics: builder.query({
      query: (params) => ({ url: `/domain-statistics`, method: 'GET', params }),
      providesTags: ['domainStatistics'],
    }),
    exportDomainsCsv: builder.mutation({
      query: (params) => ({ url: `/domain-statistics/export`, method: 'POST', params }),
    }),
  }),
});

export const { useGetDomainStatisticsQuery, useLazyGetDomainStatisticsQuery, useExportDomainsCsvMutation } =
  domainStatisticsApiSlice;

const domainDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainDetails: builder.query({
      query: (domain) => ({ url: `/domains/${domain}/config`, method: 'GET' }),
      providesTags: ['domainDetails'],
    }),
    getExtraStats: builder.query({
      query: (params) => ({ url: '/analytics/stats', method: 'GET', params }),
      providesTags: ['domainDetails'],
    }),
    updateDomainDetails: builder.mutation({
      query: ({ domain, ...body }) => ({ url: `/domains/${domain}/config`, method: 'PATCH', body }),
      invalidatesTags: ['domainDetails'],
    }),
  }),
});

export const { useGetDomainDetailsQuery, useUpdateDomainDetailsMutation, useGetExtraStatsQuery } =
  domainDetailsApiSlice;
