import React from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, Flex, Typography, Popconfirm, Skeleton, message, Alert, theme } from 'antd';
import { LeftOutlined, DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';

import AdminTemplate from '../../layout/AdminTemplate';
import {
  useGetRankedDomainQuery,
  useDeleteRankedDomainMutation,
  useUpdateRankedDomainMutation,
} from './keywordsDomainsApi';
import DomainKeywordsModal from './DomainKeywordsModal';
import { useEvent } from '../events/hooks/useEvent';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';

const NO_KEYWORDS_DESCRIPTION = 'In order to see the tracking result make sure to have at least one keyword';
const NOT_READY_MESSAGE = 'Tracking will be available in a couple of hours';

function KeywordsIFrame({ source, hasKeywords }) {
  if (!source) {
    return (
      <Alert
        showIcon
        type="info"
        message="Tracking not ready"
        description={hasKeywords ? NOT_READY_MESSAGE : NO_KEYWORDS_DESCRIPTION}
        style={{ maxWidth: '800px' }}
      />
    );
  }
  return <iframe style={{ height: '100%', width: '100%' }} src={source}></iframe>;
}

function DomainDetailsPage() {
  const location = useLocation();
  const { domainId } = useParams();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { track } = useEvent();
  const [iframeKey, setIframeKey] = React.useState(0); // iframe key to force re-render after keywords update
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const domainDetailsResult = useGetRankedDomainQuery(domainId);
  const [deleteDomain, deleteDomainResult] = useDeleteRankedDomainMutation();
  const [updateDomain, updateDomainResult] = useUpdateRankedDomainMutation();

  const domain = location.state?.domain || domainDetailsResult.data?.domain;

  const saveKeywords = (keywords) => updateDomain({ id: domainId, body: { keywords } });

  React.useEffect(() => {
    if (deleteDomainResult.isError) {
      messageApi.error('Failed to delete domain');
    } else if (deleteDomainResult.isSuccess) {
      track('Rank Tracker: Domain Delete clicked', {
        keywords_total_count: domainDetailsResult.data?.totalKeywords,
        keywords_used_count: domainDetailsResult.data?.usedKeywords,
        domain,
      });
      navigate('/rank-tracker');
    } else if (updateDomainResult.isError) {
      messageApi.error('Failed to update domain');
    } else if (updateDomainResult.isSuccess) {
      messageApi.success('Keywords updated successfully');
      setEditModalOpen(false);
      setIframeKey((prev) => prev + 1);
    }
  }, [
    deleteDomainResult.isError,
    deleteDomainResult.isSuccess,
    updateDomainResult.isError,
    updateDomainResult.isSuccess,
  ]);

  return (
    <AdminTemplate>
      {contextHolder}

      <Flex align="center" justify="space-between">
        <Flex align="center" gap="large">
          <Link to="/rank-tracker">
            <Button icon={<LeftOutlined />} type="link">
              Back
            </Button>
          </Link>
          {!domain ? (
            <Skeleton.Input block active />
          ) : (
            <Typography.Title level={2} style={{ margin: 0 }}>
              {domain}
            </Typography.Title>
          )}
        </Flex>

        <div>
          <Popconfirm
            title={`Delete "${domain}"`}
            description="Are you sure to delete this domain?"
            onConfirm={() => deleteDomain(domainId)}
            okText="Delete"
            cancelText="Cancel"
            okType="danger"
            icon={<WarningOutlined />}
            placement="left"
          >
            <ButtonWithRole
              disabledFor={[USER_ROLES.GUEST, USER_ROLES.BILLING_MANAGER]}
              danger
              icon={<DeleteOutlined />}
              type="link"
              loading={deleteDomainResult.isLoading}
            >
              Remove Domain
            </ButtonWithRole>
          </Popconfirm>
          <ButtonWithRole
            disabledFor={[USER_ROLES.GUEST, USER_ROLES.BILLING_MANAGER]}
            icon={<EditOutlined />}
            onClick={() => setEditModalOpen(true)}
          >
            Edit Keywords
          </ButtonWithRole>
        </div>
      </Flex>

      <div style={{ marginTop: token.marginMD, height: '100%' }}>
        <KeywordsIFrame
          key={iframeKey}
          source={domainDetailsResult.data?.keywordComViewKeyLink}
          hasKeywords={domainDetailsResult.data?.keywords.length}
        />
      </div>

      <DomainKeywordsModal
        loading={updateDomainResult.isLoading}
        open={editModalOpen}
        keywords={domainDetailsResult.data?.keywords}
        onClose={() => setEditModalOpen(false)}
        onUpdate={saveKeywords}
        maxKeywords={domainDetailsResult.data?.totalKeywords}
        usedKeywords={domainDetailsResult.data?.usedKeywords}
        domain={domain}
      />
    </AdminTemplate>
  );
}

export default DomainDetailsPage;
