import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal, getContent, openModal } from '../actions/pageActions';
import { regenerateApiToken } from '../actions/prerenderUserActions';
import config from '../assets/config';
import {
  Alert as LegacyAlert,
  Card,
  Container,
  Header,
  ListGroup,
  ListGroupItem,
  NavTabs,
} from '../components/CssFrameworkComponents';
import ErrorLoader from '../components/ErrorLoader';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

import { getHashParam } from '../assets/hashParams';
import GeneralSettings from '../features/settings/generalSettings/generalSettings';
import { saveSettings, setEmailPreferences } from '../features/settings/redux/settingsActions';
import './SettingsPage.css';
import { Switch } from 'antd';
import { SwitchWithRole } from '../components/ButtonWithRole';
import USER_ROLES from '../features/auth/userRoles';

const cid = 'SettingsPage';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getHashParam('tab') || 'general',
      selectedItems: [],
      showCheckListOptions: true,
      customMessage: null,
      querySearchInProgress: false,
    };
    this.lastSearch = config.pastDate;
    this.days = [];
    for (let i = 1; i < 31; i++) this.days.push(i);
  }

  componentDidMount() {
    const { cm, language, getContent: doGetContent } = this.props;
    if (!cm) doGetContent({ groups: ['settings', 'default'], lang: language });
  }

  updateEmailPreferences(update) {
    const {
      settings,
      prerenderUser,
      setEmailPreferences: doSetEmailPreferences,
      saveSettings: doSaveSettings,
    } = this.props;

    const newEmailPreferences = {
      ...settings.emailPreferences,
      ...update,
    };
    doSetEmailPreferences(newEmailPreferences);
    doSaveSettings({
      settings: {
        ...settings,
        emailPreferences: newEmailPreferences,
      },
      userId: prerenderUser.id,
    });
  }

  didReceiveAction({ action, payload }) {
    const { settings } = this.props;
    const { closeModal: doCloseModal } = this.props;
    const { activeTab } = this.state;

    switch (action) {
      case 'modalCancelled':
        doCloseModal();
        break;
      case 'tabSelected':
        if (activeTab !== payload) {
          this.setState({ activeTab: payload });
        }
        break;
      case 'toggle75%Email':
        {
          const update = { send75Percent: !settings.emailPreferences.send75Percent };
          this.updateEmailPreferences(update);
        }
        break;
      case 'toggle100%Email':
        {
          const update = { send100Percent: !settings.emailPreferences.send100Percent };
          this.updateEmailPreferences(update);
        }
        break;
      case 'toggleSuggestedEmail':
        {
          const update = { sendSuggested: !settings.emailPreferences.sendSuggested };
          this.updateEmailPreferences(update);
        }
        break;
      case 'togglePageReport':
        {
          const update = { emailReportPeriod: settings.emailPreferences.emailReportPeriod ? null : 'WEEKLY' };
          this.updateEmailPreferences(update);
        }
        break;
      default:
        console.warn('Unknown action:', action); // TODO: handle error
    }
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, prerenderUser, settings } = this.props;
    const { customMessage, activeTab } = this.state;
    if (!cm) return null;
    const tabCm = cm[activeTab];

    return (
      <AdminTemplate metaTags={cm.meta}>
        <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
          <div className="container-fluid mt-4 mb-0">
            <LegacyAlert
              className="col-12"
              content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : customMessage}
              onAction={(action) => this.didReceiveAction(action)}
            />
          </div>
        </ErrorLoader>
        <Header title="Settings">
          <NavTabs
            tabs={[
              { id: 'general', text: cm.header.tabs.general.text },
              { id: 'emailPreferences', text: cm.header.tabs.emailPreferences.text },
            ]}
            activeTab={activeTab}
            onAction={(action) => this.didReceiveAction(action)}
          />
        </Header>
        <Container fluid addClass={'p-0'}>
          {/* Email Preferences */}
          {activeTab === 'emailPreferences' && (
            <div className="row">
              <Card body>
                <ListGroup>
                  <ListGroupItem>
                    <div className="col">
                      <h4 className="font-weight-base mb-1">{tabCm.send75Percent.title}</h4>
                      <small className="text-muted">{tabCm.send75Percent.sub}</small>
                    </div>
                    <div className="col-auto">
                      <SwitchWithRole
                        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                        checked={settings.emailPreferences.send75Percent}
                        onClick={() => this.didReceiveAction({ action: 'toggle75%Email' })}
                        disabled={prerenderUser.inProgress}
                      />
                    </div>
                  </ListGroupItem>
                  <ListGroupItem>
                    <div className="col">
                      <h4 className="font-weight-base mb-1">{tabCm.send100Percent.title}</h4>
                      <small className="text-muted">{tabCm.send100Percent.sub}</small>
                    </div>
                    <div className="col-auto">
                      <SwitchWithRole
                        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                        checked={settings.emailPreferences.send100Percent}
                        onClick={() => this.didReceiveAction({ action: 'toggle100%Email' })}
                        disabled={prerenderUser.inProgress}
                      />
                    </div>
                  </ListGroupItem>
                  {prerenderUser.isMeteredBilling && (
                    <ListGroupItem>
                      <div className="col">
                        <h4 className="font-weight-base mb-1">{tabCm.sendSuggested.title}</h4>
                        <small className="text-muted">{tabCm.sendSuggested.sub}</small>
                      </div>
                      <div className="col-auto">
                        <SwitchWithRole
                          disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                          checked={settings.emailPreferences.sendSuggested}
                          onClick={() =>
                            this.didReceiveAction({
                              action: 'toggleSuggestedEmail',
                            })
                          }
                          disabled={prerenderUser.inProgress}
                        />
                      </div>
                    </ListGroupItem>
                  )}
                  <ListGroupItem>
                    <div className="col">
                      <h4 className="font-weight-base mb-1">{tabCm.sendPageReport.title}</h4>
                      <small className="text-muted">{tabCm.sendPageReport.sub}</small>
                    </div>
                    <div className="col-auto">
                      <SwitchWithRole
                        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                        checked={!!settings.emailPreferences.emailReportPeriod}
                        onClick={() => this.didReceiveAction({ action: 'togglePageReport' })}
                        disabled={
                          prerenderUser.inProgress ||
                          // Non weekly report is set by us, so it can't be managed
                          (settings.emailPreferences.emailReportPeriod !== 'WEEKLY' &&
                            settings.emailPreferences.emailReportPeriod !== undefined &&
                            settings.emailPreferences.emailReportPeriod !== null)
                        }
                      />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </div>
          )}

          {activeTab === 'general' && (
            <div className="row">
              <GeneralSettings />
            </div>
          )}
        </Container>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    ssrEntryUrl: state.page.ssrEntryUrl,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
    language: state.page.language,
    prerenderUser: state.prerenderUser,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      openModal,
      closeModal,
      regenerateApiToken,
      saveSettings,
      getSelection,
      setEmailPreferences,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(SettingsPage));
