import { Descriptions } from 'antd';

import USER_ROLES from '../auth/userRoles';
import { AppUserRoles } from './appUserRoles';

const ROLES_DESCRIPTIONS = [
  {
    key: USER_ROLES.SUPER_ADMIN,
    label: AppUserRoles[USER_ROLES.SUPER_ADMIN],
    children: 'The creator of the account. Full access to the dashboard and can invite, edit, or remove users.',
  },
  {
    key: AppUserRoles[USER_ROLES.ADMIN],
    label: 'Admin',
    children: 'Full access to the dashboard and can invite, edit, or remove users, except for the Account Owner.',
  },
  {
    key: AppUserRoles[USER_ROLES.BILLING_MANAGER],
    label: 'Billing Manager',
    children:
      "Full access to the billing menu, but 'View Only' access to the rest of the dashboard. Can invite other Billing Managers and Guests.",
  },
  {
    key: AppUserRoles[USER_ROLES.TEAM_MEMBER],
    label: 'Team Member',
    children:
      "Full access to the dashboard, but 'View Only' access to billing and security. Can invite other Team Members and Guests.",
  },
  {
    key: AppUserRoles[USER_ROLES.GUEST],
    label: 'Guest',
    children: "'View Only' access to the entire dashboard.",
  },
];

function RolesDescriptions() {
  return <Descriptions items={ROLES_DESCRIPTIONS} column={1} bordered size="small" />;
}

export default RolesDescriptions;
