/**************************************

THIS IS A PUBLIC CLIENT SIDE CONFIG!

**************************************/
const ENV = process.env.REACT_APP_STAGE;
const projectId = 'prerender';

let tawkToIds = '6656f45f981b6c5647761295/1hv1ql57j';

const serverUrl = {
  webServer: '',
  cmsServer: '',
  authServer: '',
  dataServer: '',
  restServer: 'http://localhost:3030',
  insightServer: 'http://localhost:3000',
};

let logoUrl = '/assets/img/v2/LogoWithTextVector.svg'; //local env

let userflowToken;
if (ENV === 'production') {
  userflowToken = `ct_yzhsrh2swja7pnry3rk5oqzevq`;
} else {
  userflowToken = `ct_oquynfr3hng2zjkxzleaqyk77m`;
}
let userSnapSpaceApiKey;
if (ENV === 'production') {
  userSnapSpaceApiKey = `addc885a-9ba6-4b75-9921-812e354b240e`;
} else {
  userSnapSpaceApiKey = `1b6b862e-de32-445a-bbaa-a8a4b4f565b0`;
}

if (ENV === 'production') {
  serverUrl.webServer = 'https://dashboard-api.prerender.io';
  serverUrl.cmsServer = 'https://dashboard-api.prerender.io';
  serverUrl.authServer = 'https://dashboard-api.prerender.io';
  serverUrl.dataServer = 'https://dashboard-api.prerender.io';
  serverUrl.restServer = 'https://api.prerender.io';
  serverUrl.insightServer = 'https://insight.internal.prerender.io';

  tawkToIds = '6630da811ec1082f04e940d9/1hsndb2sm';
  logoUrl = '/assets/img/v2/LogoWithTextVector.svg';
} else if (ENV === 'staging') {
  serverUrl.webServer = 'https://dashboard-api.prerender-staging.dev';
  serverUrl.cmsServer = 'https://dashboard-api.prerender-staging.dev';
  serverUrl.authServer = 'https://dashboard-api.prerender-staging.dev';
  serverUrl.dataServer = 'https://dashboard-api.prerender-staging.dev';
  serverUrl.restServer = 'https://rest-api.prerender-staging.dev';
  serverUrl.insightServer = 'https://insight.internal.prerender-staging.dev';

  tawkToIds = '6630da811ec1082f04e940d9/1hsnfb7a2';
  logoUrl = '/assets/img/v2/LogoWithTextVector.svg';
}

const regexValidText = new RegExp(/^[^~]*$/, 'g'); // only don't allow ~
const gaOptions = ENV === 'production' ? { debug: false } : { debug: false, cookieDomain: 'none' };

// setup Cookie Options for client-side set cookies via universal-cookie:
const cookieOptions =
  ENV === 'production'
    ? {
        path: '/',
        // domain: `.${mainDomain}`,
        // expires: new Date(2291656749000),
        secure: true,
      }
    : {
        path: '/',
        // domain: `.${mainDomain}`
      };

// hold application secrets and config:
module.exports = {
  apiUrl: {
    webServer: serverUrl.webServer,
    dataServer: serverUrl.dataServer,
    authServer: serverUrl.authServer,
    cmsServer: serverUrl.cmsServer,
    restServer: serverUrl.restServer,
    insightServer: serverUrl.insightServer,
  },

  cookieOptions,
  sessionName: `${projectId}Session`,

  // Google Analytics:
  ga: {
    trackingId: '', // 'UA-179554564-1',
    options: gaOptions,
  },

  regexValid: {
    firstname: /^([0-9a-zA-ZÀ-ž äÄöÖüÜßèé#&+.-]*)$/,
    lastname: /^([0-9a-zA-ZÀ-ž äÄöÖüÜßèé#&+.-]*)$/,
    organization: /^([a-zA-Z0-9À-ž äÄöÖüÜßèé,;:'\-()#%&$@!/?.\\r\n]*)$/,
    email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    username: /^([a-zA-Z0-9_@\- \x7f-\xff#.]*)$/,
    // eslint-disable-next-line no-useless-escape
    urlPath: /^([a-zA-Z0-9\-]*)$/,
    // eslint-disable-next-line no-useless-escape
    password: /^([a-zA-Z0-9äÄöÖüÜßèé@?!#\$%&\*+\/=_.,-]*)$/,
    headline: regexValidText,
    posting: regexValidText,
    text: regexValidText,
    twitter: /^@?(\w){1,15}$/,
    // eslint-disable-next-line
    url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z‌​]{2,63}\b([-a-zA-Z0-9‌​@:%_\+.~#?&=]*)/,
    gtLtEqNum: /^[><=]?(>=)?(<=)?(\d+)?(\.[0-9]+)?$/,
  },

  defaultProfilePicture: '/assets/img/team/anonymous.jpg',
  // defaultOgBackgroundImage: `https://${mainDomain}/assets/img/backgrounds/ogbackground.jpg`,
  SpinningWheelUrl: '/assets/img/SpinningWheel.gif',
  warningIconUrl: '/assets/img/warning.png',
  successIconUrl: '/assets/img/checkmark.png',
  logoUrl,

  pastDate: new Date(1291656749000),
  futureDate: new Date(2291656749000),

  freePlanCachedPagesLimit: 250,
  cacheRefreshRatePerMin: 1,

  userflowToken,
  userSnapSpaceApiKey,
  tawkToIds,
};
