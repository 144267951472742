import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, message, Alert as AntDAlert, Card, Typography, ConfigProvider, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { getContent } from '../actions/pageActions';
import { togglePauseRecaching } from '../actions/prerenderUserActions';
import { recacheQueueSearch } from '../actions/recacheQueueActions';
import config from '../assets/config';
import { createHtmlContentFromCmText } from '../assets/contentFormatter';
import { diffInSecs, getAgeString } from '../assets/time';
import { renderingQueueApi, RenderingQueueEmptyTable } from '../features/RenderingQueue';
import { useEvent } from '../features/events/hooks/useEvent';
import AdminTemplate from '../layout/AdminTemplate';
import { Alert, Header, NavTabs } from '../components/CssFrameworkComponents';
import ErrorLoader from '../components/ErrorLoader';
import requireAuth from '../components/hocs/requireAuth';
import { BlurringOverlay } from '../components/BlurringOverlay/BlurringOverlay';
import { Pagination } from '../components/PaginationLib';
import { Layout } from 'antd';
import ButtonWithRole from '../components/ButtonWithRole';
import USER_ROLES from '../features/auth/userRoles';

const { Text } = Typography;
const { Content } = Layout;

const cid = 'RecacheQueuePage';
const PAGE_SIZE = 100;
const SHOW_NEVER = true;

const DAY_IN_MS = 24 * 60 * 60 * 1000;
const getDelayBetweenUrls = (user) => {
  const { cacheFreshness, allURLCount, customRecacheDelay } = user;
  let multiplier = 1;
  let delayBetweenURLs = Math.floor((cacheFreshness * DAY_IN_MS) / (allURLCount || 1));

  if (customRecacheDelay) {
    delayBetweenURLs = customRecacheDelay;
  }

  if (delayBetweenURLs > 60000) {
    delayBetweenURLs = 60000;
  }

  if (!customRecacheDelay) {
    multiplier = 0.99;
  }
  return Math.ceil(delayBetweenURLs * multiplier);
};

class RecacheQueuePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'priority',
      customMessage: null,
      querySearchInProgress: false,
      initialSearchQuery: {
        priority: props.recacheQueue.priority.params.query,
        standard: props.recacheQueue.standard.params.query,
      },
      openConfirmModal: false,
    };
    this.lastSearch = { priority: config.pastDate, standard: config.pastDate };
    const { track } = useEvent();
    this.track = track;
  }

  componentDidMount() {
    const { cm, language, getContent: doGetContent, recacheQueue } = this.props;
    if (!cm) doGetContent({ groups: ['recacheQueue', 'default'], lang: language });
    this.performSearch('priority', { ...recacheQueue.priority.params, page: 0, pageSize: PAGE_SIZE, sort: 'date' });
    this.performSearch('standard', { ...recacheQueue.standard.params, page: 0, pageSize: PAGE_SIZE, sort: 'date' });
  }

  static getDerivedStateFromProps(props, state) {
    // transfers an updated copy of props into local state for values in local state that are
    // e.g. supposed to trigger actions in componentDidUpdate():
    if (props.cm && !props.prerenderUser.inProgress && state.customMessage === null) {
      if (props.prerenderUser.recachingDisabled) {
        return { customMessage: { text: props.cm.warnings.blocked.map(createHtmlContentFromCmText), color: 'danger' } };
      }
      if (props.prerenderUser.userPausedRecaching) {
        return {
          customMessage: {
            text: props.cm.warnings.paused.text,
            linkText: props.cm.warnings.paused.link,
            onLinkClick: { action: 'resume' },
            color: 'warning',
          },
        };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { recacheQueue } = this.props;
    const { activeTab } = this.state;
    if (
      !recacheQueue[activeTab].inProgress &&
      prevState.activeTab !== activeTab &&
      diffInSecs(new Date(), this.lastSearch[activeTab]) > 20
    ) {
      this.reloadData();
    }
  }

  performQuerySearch(query) {
    this.setState({ querySearchInProgress: false });
    this.performSearch(this.state.activeTab, {
      ...this.props.recacheQueue[this.state.activeTab].params,
      page: 0,
      query,
    });
  }

  performSearch(queue = 'priority', params) {
    const { recacheQueueSearch: doRecacheQueueSearch } = this.props;
    const now = new Date();
    this.lastSearch =
      queue === 'priority' ? { ...this.lastSearch, priority: now } : { ...this.lastSearch, standard: now };
    doRecacheQueueSearch(queue, params);
  }

  reloadData(page = this.props.recacheQueue[this.state.activeTab].params.page) {
    const { recacheQueue } = this.props;
    this.performSearch(this.state.activeTab, { ...recacheQueue[this.state.activeTab].params, page });
  }

  didReceiveAction({ action, payload }) {
    const {
      prerenderUser,
      recacheQueue,
      togglePauseRecaching: doTogglePauseRecaching,
      clearManualQueue: doClearManualQueue,
    } = this.props;
    const { activeTab } = this.state;
    switch (action) {
      case 'changePage':
        this.performSearch(activeTab, { ...recacheQueue[activeTab].params, page: payload });
        break;
      case 'pause':
        doTogglePauseRecaching(prerenderUser);
        break;
      case 'resume':
        doTogglePauseRecaching(prerenderUser, () => {
          this.setState({ customMessage: null });
        });
        break;
      case 'tabSelected':
        if (activeTab !== payload) {
          this.setState({
            activeTab: payload,
            initialSearchQuery: {
              priority: recacheQueue.priority.params.query,
              standard: recacheQueue.standard.params.query,
            },
          });
        }
        break;
      case 'openClearManualQueueModal':
        this.setState({ openConfirmModal: true });
        break;
      case 'clearManualQueue':
        doClearManualQueue()
          .then(() => {
            this.track('Render Queue Cleared Manually', {
              subscription_plan: prerenderUser.chargebeePlanId,
            });
            this.reloadData();
          })
          .catch(() => {
            message.error('Sorry, something went wrong. Please try again later.', 10);
          });
        break;
      default: // TODO: handle error
        console.warn(`Unknown action: ${action}`);
        break;
    }
  }

  getTableColumns() {
    const { cm } = this.props;
    const { activeTab } = this.state;

    if (!cm) return [];
    const cms = cm[activeTab];

    const columns = [
      {
        title: cms.table.header.url,
        key: 'url',
        dataIndex: 'url',
        width: '99%',
        className: 'text-truncate url-shortener',
      },
      {
        title: cms.table.header.requested,
        key: 'requested',
        className: 'col-min',
        render: (_text, item) => getAgeString(new Date(item.requested_recache_at), 'ago', 'long'),
        onlyPriorityTab: true,
      },
      {
        title: cms.table.header.recached,
        key: 'recached',
        className: 'col-min',
        render: (_text, item) => getAgeString(new Date(item.last_refresh), 'ago', 'long', SHOW_NEVER),
      },
    ];

    if (activeTab === 'priority') return columns;

    return columns.filter((col) => !col.onlyPriorityTab);
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, recacheQueue, prerenderUser } = this.props;
    const { customMessage, querySearchInProgress, activeTab } = this.state;
    const queue = recacheQueue[activeTab];
    if (!cm) return null;
    const cms = cm[activeTab];
    const prioLen = recacheQueue.priority.pages.length;
    const stanLen = recacheQueue.standard.pages.length;
    const delayBetweenURLs = getDelayBetweenUrls(prerenderUser);
    let recacheMetrics = {
      num10Sec: 0,
      num30Sec: 0,
      num1Min: 0,
      num10Min: 0,
      num1Hour: 0,
      num1Day: 0,
    };
    if (prerenderUser) {
      recacheMetrics = {
        num10Sec: Math.ceil(10000 / delayBetweenURLs),
        num30Sec: Math.ceil(30000 / delayBetweenURLs),
        num1Min: Math.ceil(60000 / delayBetweenURLs),
        num10Min: Math.ceil(600000 / delayBetweenURLs),
        num1Hour: Math.ceil((60000 * 60) / delayBetweenURLs),
        num1Day: Math.ceil((60000 * 60 * 24) / delayBetweenURLs),
      };
    }

    const isLoading = queue.inProgress || prerenderUser.inProgress || querySearchInProgress;

    return (
      <AdminTemplate metaTags={cm.meta}>
        <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
          <div className="container-fluid mt-4 mb-0">
            <Alert
              className="col-12"
              content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : customMessage}
              onAction={(action) => this.didReceiveAction(action)}
            />
          </div>
        </ErrorLoader>
        <Modal
          open={this.state.openConfirmModal}
          title="Clean manual/API queue"
          onOk={() => {
            this.didReceiveAction({ action: 'clearManualQueue' });
            this.setState({ openConfirmModal: false });
          }}
          onCancel={() => this.setState({ openConfirmModal: false })}
        >
          <p>Do you want to clean manual/API queue?</p>
        </Modal>

        <Header title="Scheduled Rendering">
          <Text type="secondary">URLs prompted for caching and re-caching either manually or automatically</Text>
          <NavTabs
            tabs={[
              {
                id: 'priority',
                text: cm.header.tabs.priority.text,
                badge: prioLen > 0 && prioLen < PAGE_SIZE && recacheQueue.priority.params.page === 1 ? prioLen : null,
              },
              {
                id: 'standard',
                text: cm.header.tabs.standard.text,
                badge: stanLen > 0 && stanLen < PAGE_SIZE && recacheQueue.standard.params.page === 1 ? stanLen : null,
              },
            ]}
            activeTab={activeTab}
            onAction={(action) => this.didReceiveAction(action)}
          />
        </Header>

        <Content>
          <div data-show="true" className="ant-alert ant-alert-info mb-5" role="alert">
            <div className="ant-alert-content">
              {activeTab === 'priority' ? (
                <AntDAlert
                  type="info"
                  showIcon
                  message={
                    <span>
                      <span>
                        Manual/API entries are created manually by adding URLs or selecting them to be re-cached from
                        the Cache Manager URL list or via{' '}
                      </span>
                      <a href="https://docs.prerender.io/docs/6-api?highlight=Recache" target="_blank">
                        API calls
                      </a>
                    </span>
                  }
                />
              ) : (
                <AntDAlert
                  type="info"
                  showIcon
                  message="Automatic (re)caching based on cache expiration settings in Cache Manager"
                />
              )}
            </div>
          </div>

          {activeTab === 'priority' ? (
            <div className="row">
              <div className="col table-responsive mb-0">
                <table className="table table-sm table-nowrap">
                  <caption className="caption-top">Render Speed (Number of URLs per interval)</caption>
                  <thead>
                    <tr>
                      <th scope="col">in 1 min</th>
                      <th scope="col">in 1 hour</th>
                      <th scope="col">in 1 day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{recacheMetrics.num1Min}</td>
                      <td>{recacheMetrics.num1Hour}</td>
                      <td>{recacheMetrics.num1Day}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-auto">
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  type="primary"
                  danger
                  onClick={() => {
                    this.didReceiveAction({ action: 'openClearManualQueueModal' });
                  }}
                  disabled={!prerenderUser.trackingCodeInstalled}
                >
                  Clear Manual Queue
                </ButtonWithRole>
              </div>
            </div>
          ) : (
            ''
          )}
          <Card
            title="Pages scheduled for recaching"
            extra={
              <Button
                icon={<ReloadOutlined />}
                onClick={() => this.reloadData()}
                disabled={queue.inProgress}
                name="reload"
              />
            }
          >
            <BlurringOverlay
              enabled={!prerenderUser.trackingCodeInstalled}
              location="Scheduled Rendering page"
              content="Complete Prerender Integration to see all pages up for re-caching"
            />

            <ConfigProvider
              renderEmpty={() =>
                !queue.params.page && queue.pages.length === 0 && !queue.inProgress ? (
                  <RenderingQueueEmptyTable
                    hasQuery={queue.params.query}
                    isPriorityTab={activeTab === 'priority'}
                    labels={cms}
                  />
                ) : (
                  <span></span>
                )
              }
            >
              <Table
                bordered={!isLoading}
                rowKey="id"
                loading={isLoading}
                columns={this.getTableColumns()}
                dataSource={queue.pages}
                pagination={false}
                showHeader={!!queue.pages.length}
                scroll={{ x: true }}
              />
            </ConfigProvider>
            <Pagination
              onPageChange={(action) => this.didReceiveAction(action)}
              stats={{
                total: queue.stats.total,
                amt: queue.stats.amt,
                pages: queue.stats.pages,
                page: queue.params.page,
                amtpp: queue.params.pageSize,
              }}
            ></Pagination>
          </Card>
        </Content>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    ssrEntryUrl: state.page.ssrEntryUrl,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
    language: state.page.language,
    recacheQueue: state.recacheQueue,
    prerenderUser: state.prerenderUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      recacheQueueSearch,
      togglePauseRecaching,
      clearManualQueue: renderingQueueApi.endpoints.clearManualQueue.initiate,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(RecacheQueuePage));
