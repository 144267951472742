import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Progress, Spin, Button } from 'antd';
import PlanUpgradeWhiteIcon from '../../../assets/icons/plan-upgrade-white-icon.svg';
import { createUsNumberFormat } from '../../../utils/createUsNumberFormat';
import { formatCentsToDollarLocale, formatToDollar } from '../../../assets/currencies';
import { useEvent } from '../../events/hooks/useEvent';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';
import { useGetPackagesQuery } from '../api/packageApiSlice';
import { AddonBillingType } from '@prerender/billing-shared';
import { experimentalClient } from '../../../library/experimentalClient';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const CounterBar = ({ count, limit, isLegacyUser }) => {
  const counterPercentage = (count / limit) * 100;
  return (
    <div>
      {isLegacyUser ? (
        <div className="d-flex align-items-start justify-content-between">
          <h4>Number of cached pages</h4>
        </div>
      ) : (
        <div className="d-flex align-items-start justify-content-between">
          <h4>Render Counter</h4>
          {counterPercentage > 100 && <span className="text-sm text-danger">Extra Renders!</span>}
        </div>
      )}

      <Progress
        percent={counterPercentage}
        strokeColor={counterPercentage > 100 ? '#FFBA53' : '#6FC49D'}
        showInfo={false}
      />
      <div>
        <span className={`text-sm text-grey`}>{count.toLocaleString()}</span>
        <span>/</span>
        <span className="text-sm font-weight-bold">{limit.toLocaleString()}</span>
      </div>
    </div>
  );
};

export const BillingOverviewPlan = ({ user, isUserOnCustomPlan }) => {
  const isLegacyUser = !user.isMeteredBilling;
  const currentCount = isLegacyUser ? user.numPagesCached : user.renderCounter;

  const { track } = useEvent();

  const { data: packagesData, isFetching: packagesLoading, error: packagesError } = useGetPackagesQuery();

  const onUpgradeLinkClick = React.useCallback(() => {
    track('Billing Plans Page Viewed', { subscription_plan: user.planName, location: 'Upgrade Button' });
  }, [user]);

  const activePackages = useMemo(
    () => packagesData?.data.filter((p) => p.isActive && p.billingType === AddonBillingType.Subscription),
    [packagesData]
  );

  const [selectedCustomDeal, setSelectedCustomDeal] = React.useState(null);

  useEffect(() => {
    if (isUserOnCustomPlan) {
      experimentalClient(user.token, '/deals').then((response) => {
        const sorterd = response.data.sort((a, b) => (a.beginsAt > b.beginsAt ? -1 : 1));

        if (response.data.length) {
          setSelectedCustomDeal(sorterd[0]);
        }
      });
    }
  }, [isUserOnCustomPlan]);

  const customDealBeginsAt = selectedCustomDeal?.beginsAt
    ? dayjs(selectedCustomDeal?.beginsAt).format('YYYY-MM-DD')
    : '-';

  const customRenewalDateAt = selectedCustomDeal?.renewalAt
    ? dayjs(selectedCustomDeal?.renewalAt).format('YYYY-MM-DD')
    : '-';

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="font-weight-bold">{`${user.planName} Plan`}</h3>
        <span>
          {typeof user.customPrice === 'number' ? formatToDollar(user.customPrice) : formatToDollar(user.costPerMonth)}
        </span>
      </div>
      {isUserOnCustomPlan ? (
        <>
          <p className="mt-3">
            <span className="text-grey">
              Your plan and billing details are determined by a custom agreement with Prerender, created on{' '}
              {customDealBeginsAt}
            </span>{' '}
          </p>
          <p>
            <Link to="/custom-deal" component={(props) => <Button {...props} type="link" htmlType="span" />}>
              See your deal details here
            </Link>
          </p>
          <p>
            <span>For more information, please contact </span>
            <a style={{ color: '#63B190' }} className="font-weight-bold" href="mailto:growth@prerender.io">
              growth@prerender.io
            </a>
            <span className="text-grey">.</span>
          </p>
        </>
      ) : (
        <>
          <ul className="mt-3 pl-4">
            <li>
              {isLegacyUser ? <span>No. of cached pages: </span> : <span>No. of Renders: </span>}

              <span className="font-weight-bold">{createUsNumberFormat(user?.plan.limit)}</span>
            </li>
            {user.plan.current && user.plan.current.addonCostInCents ? (
              <li>
                <span>Cost Per 1000 Extra Renders: </span>
                <span className="font-weight-bold">
                  {formatCentsToDollarLocale(user.plan.current.addonCostInCents)}
                </span>
              </li>
            ) : (
              <></>
            )}
            {user.plan.cacheRefreshInterval && (
              <li>
                <span>Cache Freshness Interval: </span>
                <span className="font-weight-bold">{formatCacheRefreshInterval(user.plan.cacheRefreshInterval)}</span>
              </li>
            )}
          </ul>
          <h4 className="font-weight-bold">Packages</h4>
          {packagesLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          )}
          {!packagesLoading && packagesError && (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <p style={{ color: 'red' }}>An unexpected error occured, please try again by reloading the page.</p>
            </div>
          )}
          {!packagesLoading &&
            !packagesError &&
            (activePackages.length ? (
              <ul className="mt-3 pl-4">
                {activePackages.map((p) => {
                  return (
                    <li key={p.name}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{p.name}</span>
                        <span>{formatToDollar(p.costInCents / 100)}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>You don't have active packages</p>
            ))}
        </>
      )}
      <div className="mt-5">
        {isUserOnCustomPlan ? (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Render Counter</h4>
              {currentCount.toLocaleString()}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Renders Included</h4>
              {selectedCustomDeal?.renders || '-'}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Renewal date</h4>
              {customRenewalDateAt}
            </div>
          </>
        ) : (
          <CounterBar isLegacyUser={isLegacyUser} count={currentCount} limit={user.plan.limit} />
        )}
      </div>
      <div className="row justify-content-center text-center">
        <div className="d-flex" onClick={onUpgradeLinkClick}>
          {!isUserOnCustomPlan ? (
            <>
              <hr />
              <Link to="/billing/plans">
                <ButtonWithRole
                  disabledFor={[USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                  type="primary"
                  style={{ width: '100%' }}
                >
                  <img src={PlanUpgradeWhiteIcon} />
                  <span className="ml-3">Upgrade Plan</span>
                </ButtonWithRole>
              </Link>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
