import { useState, useMemo } from 'react';
import { logoUrl } from '../assets/config';
import { Layout, Menu, Tag, Space } from 'antd';
import {
  DashboardOutlined,
  ApiOutlined,
  GlobalOutlined,
  HistoryOutlined,
  PieChartOutlined,
  CloudOutlined,
  FileSyncOutlined,
  CheckSquareOutlined,
  GoogleOutlined,
  CreditCardOutlined,
  SettingOutlined,
  CompassOutlined,
  StockOutlined,
  MenuOutlined,
  LaptopOutlined,
  LockOutlined,
  EyeOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { useIsSSO } from '../hooks/useIsSSO';
import { isFreePlan } from '../chargebee/chargebee';
import { usePrerenderUser } from '../hooks/usePrerenderUser';
import { Link } from 'react-router-dom';
import './AdminTemplate.css';
import { useLocation } from 'react-router-dom';

const { Sider } = Layout;

// label: <LinkWithTag to="/page-url">test</LinkWithTag>;
function LinkWithTag({ to, children, tag = 'NEW' }) {
  return (
    <Link to={to}>
      <Space size="small">
        <span>{children}</span>
        <Tag color="purple">{tag}</Tag>
      </Space>
    </Link>
  );
}

const settingsItems = ({ sso }) => [
  {
    key: 'billing',
    label: <Link to="/billing">Billing</Link>,
    icon: <CreditCardOutlined />,
    target: '/billing',
    group: 'Settings',
    visible: true,
  },
  {
    key: 'security',
    label: <Link to="/security">Security & Access</Link>,
    icon: <LockOutlined />,
    target: '/security',
    group: 'Settings',
    visible: true,
  },
  {
    key: 'settings',
    label: <Link to="/settings">Advanced Settings</Link>,
    icon: <SettingOutlined />,
    target: '/settings',
    group: 'Settings',
    visible: true,
  },
];

const menuItems = ({ isIntegrated, paidPlan, sso }) => [
  {
    key: 'integration-wizard',
    label: <Link to="/integration-wizard">Integration Wizard</Link>,
    icon: <ApiOutlined />,
    target: '/integration-wizard',
    visible: !isIntegrated,
  },
  {
    key: 'dashboard',
    label: <Link to="/">Dashboard</Link>,
    icon: <DashboardOutlined />,
    target: '/',
    visible: true,
  },
  {
    key: 'rank-tracker',
    label: (
      <Link to="/rank-tracker">
        Rank Tracker
      </Link>
    ),
    icon: <TagOutlined />,
    target: '/rank-tracker',
    visible: true,
  },
  {
    key: 'deep-insight',
    label: <Link to="/insight">Deep Insight</Link>,
    icon: <PieChartOutlined />,
    target: '/insight',
    visible: true,
  },
  {
    key: 'cache-manager',
    label: <Link to="/cache">Cache Manager</Link>,
    icon: <CloudOutlined />,
    target: '/cache',
    visible: true,
  },
  {
    key: 'domain-manager',
    label: <Link to="/domain-manager">Domain Manager</Link>,
    icon: <LaptopOutlined />,
    target: '/domain-manager',
    visible: true,
  },
  {
    key: 'cdn-analytics',
    label: <Link to="/crawl-stats">CDN Analytics</Link>,
    icon: <GlobalOutlined />,
    target: '/crawl-stats',
    visible: true,
  },
  {
    key: 'sitemaps',
    label: <Link to="/sitemaps">Sitemaps</Link>,
    icon: <FileSyncOutlined />,
    target: '/sitemaps',
    visible: true,
  },
  {
    key: 'scheduled-rendering',
    label: <Link to="/recache-queue">Scheduled Rendering</Link>,
    icon: <HistoryOutlined />,
    target: '/recache-queue',
    visible: true,
  },
  {
    key: 'render-history',
    label: <Link to="/recache-stats">Render History</Link>,
    icon: <StockOutlined />,
    target: '/recache-stats',
    visible: true,
  },
  {
    key: 'domain-404-checker',
    label: <Link to="/domain-404-checker">404 Checker</Link>,
    icon: <CheckSquareOutlined />,
    target: '/domain-404-checker',
    visible: true,
  },
  {
    key: 'oversight',
    label: <Link to="/oversight">Oversight</Link>,
    icon: <EyeOutlined />,
    target: '/oversight',
    visible: false,
  },
  {
    key: 'gsc',
    label: <Link to="/gsc">Google Search Console</Link>,
    icon: <GoogleOutlined />,
    target: '/gsc',
    visible: true,
  },
  {
    key: 'product-portal',
    label: <Link to="/feature-request">Product Portal</Link>,
    icon: <CompassOutlined />,
    target: '/feature-request',
    visible: paidPlan,
  },
  // {
  //   key: 'custom-deal',
  //   label: 'Custom Deal Tracker',
  //   icon: <BarChartOutlined />,
  //   target: '/custom-deal',
  //   group: 'Experimental Features',
  //   visible: isIntegrated,
  // },
  // {
  //   key: 'qa',
  //   label: 'Quality Assurance',
  //   icon: <EyeOutlined />,
  //   target: '/qa',
  //   group: 'Experimental Features',
  //   visible: true,
  // },
  {
    type: 'group',
    key: 'settings',
    label: 'Settings',
    children: settingsItems({ sso }),
  },
];

const TARGET_TO_KEY = menuItems({ isIntegrated: false, paidPlan: false, sso: false, isPrerenderUser: false }).reduce(
  (acc, item) => {
    if (item.type === 'group') {
      item.children.forEach((child) => (acc[child.target] = child.key));
    } else {
      acc[item.target] = item.key;
    }
    return acc;
  },
  {}
);

function filterVisibleMenuItem(item) {
  if (item.type === 'group') {
    const children = item.children
      .filter((child) => child.visible)
      .map(({ key, label, icon }) => ({ key, label, icon }));

    if (children.length) {
      return {
        ...item,
        children,
      };
    }
  }

  if (item.visible) {
    return { key: item.key, label: item.label, icon: item.icon };
  }
}

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);

  const location = useLocation();
  const sso = useIsSSO();
  const user = usePrerenderUser();
  const paidPlan = !isFreePlan(user.plan);

  const items = useMemo(() => {
    return menuItems({
      isIntegrated: user.trackingCodeInstalled,
      paidPlan,
      sso,
    }).reduce((acc, item) => {
      const filteredItem = filterVisibleMenuItem(item);
      if (filteredItem) {
        acc.push(filteredItem);
      }
      return acc;
    }, []);
  }, [user.trackingCodeInstalled, paidPlan, sso]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const selectedKey = TARGET_TO_KEY[`/${location.pathname.split('/')[1]}`];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        setCollapsed(broken);
        setShowMenuIcon(broken);
      }}
      theme="light"
      width={260}
      style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
      className="sidebar-layout"
    >
      {showMenuIcon && (
        <div style={{ position: 'fixed', left: 0, top: 0, bottom: 0 }}>
          <MenuOutlined onClick={toggleCollapsed} className="trigger" style={{ fontSize: 24 }} />
        </div>
      )}
      <div style={{ margin: 16 }}>
        <Link to="/">
          <img src={logoUrl} className="navbar-brand-img mx-auto" alt="Prerender" />
        </Link>
      </div>
      <Menu selectedKeys={selectedKey} mode="vertical" style={{ paddingBottom: 80 }} items={items} />
    </Sider>
  );
};

export default Sidebar;
