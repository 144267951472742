import React, { Suspense } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ModalContainer } from './components/ModalLib';
import TrackerComponent from './components/TrackerComponent';
import CustifyJsSnipet from './features/custify/jsSnipet';
import { ViewEventListener } from './features/events/ViewEventListener';
import { UserFlow } from './features/userflow/UserFlow';
import { UserSnap, Zendesk, useTawkTo, useHotJar } from './features/CustomerSupport';
import './styles/app.css';

import routes from './Routes';

export default () => {
  const elements = useRoutes(routes);
  const location = useLocation();
  const shouldLoadCustomerSupport = process.env.NODE_ENV !== 'development' && location.pathname !== '/raw-html';

  Zendesk.useZendesk(shouldLoadCustomerSupport);

  useTawkTo(shouldLoadCustomerSupport);

  useHotJar();

  return (
    <RecoilRoot>
      <UserSnap.UserSnapApiProvider>
        <TrackerComponent>
          <ViewEventListener />
          <UserFlow />
          {process.env.NODE_ENV !== 'development' && <CustifyJsSnipet />}
          <Suspense fallback={<h1>Loading...</h1>}>
            <ModalContainer />
            {elements}
          </Suspense>
        </TrackerComponent>
      </UserSnap.UserSnapApiProvider>
    </RecoilRoot>
  );
};
