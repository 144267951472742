import { Steps, Flex } from 'antd';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import ImportSitemapsStep from './components/steps/ImportSitemapsStep';
import SuggestedIntegrationRouter from './components/steps/SuggestedIntegrationStep';
import ScanDomainStep from './components/steps/ScanDomainStep/ScanDomainStep';
import VerificationSuccessfulStep from './components/steps/VerificationSuccessfulStep';
import { useIntegrationWizardSteps } from './useIntegrationWizardSteps';
import { Navigate, Route, Routes } from 'react-router-dom';

const stepsConfig = [
  { path: 'domain', title: 'Scan Domain', disabled: true },
  /*{ path: 'sitemap', title: 'Import Sitemaps', component: ImportSitemapsStep, disabled: true },*/
  { path: 'suggested-integration', title: 'Integrate', disabled: true },
  { path: 'verification', title: 'Start Rendering', disabled: true },
];

const IntegrationWizardPage = () => {
  const { onGoBack, onGoNext, goToStart, currentStep, prevStep, setCurrentStep } =
    useIntegrationWizardSteps(stepsConfig);

  return (
    <AdminTemplate>
      <Flex vertical gap="large" style={{ height: '100%' }}>
        <Steps current={currentStep} items={stepsConfig} onChange={setCurrentStep} />

        <Routes>
          <Route
            path="domain"
            element={
              <ScanDomainStep
                onGoBack={onGoBack}
                onGoNext={onGoNext}
                goToStart={goToStart}
                currentStep={currentStep}
                prevStep={prevStep}
              />
            }
          />
          <Route
            path="suggested-integration/*"
            element={
              <SuggestedIntegrationRouter
                onGoBack={onGoBack}
                onGoNext={onGoNext}
                goToStart={goToStart}
                currentStep={currentStep}
                prevStep={prevStep}
              />
            }
          />
          <Route
            path="verification"
            element={
              <VerificationSuccessfulStep
                onGoBack={onGoBack}
                onGoNext={onGoNext}
                goToStart={goToStart}
                currentStep={currentStep}
                prevStep={prevStep}
              />
            }
          />
          <Route path="" element={<Navigate to="domain" />}></Route>
        </Routes>
      </Flex>
    </AdminTemplate>
  );
};

export default requireAuth(IntegrationWizardPage);
