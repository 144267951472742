import React, { useCallback, useEffect, useState } from 'react';
import AdminTemplate from '../../layout/AdminTemplate';
import requireAuth from '../../components/hocs/requireAuth';
import { Button, Card, Col, Flex, Layout, Row, Typography, message } from 'antd';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { SeoReportUrlsTable } from './SeoReportUrlsTable';
import { useExportSeoReportCSVMutation, useGetSeoReportQuery, useGetSeoReportUrlsQuery } from './seoReportApiSlice';
import { ReportDetailsCard } from './components/ReportDetailsCard';
import { SeoReportChart } from './components/SeoReportChart';
import { debounce } from 'lodash';
import { LeftOutlined } from '@ant-design/icons';
import useFeatureFlag, { SEO_REPORT_FEATURE } from '../../hooks/useFeatureFlag';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import ExportCSVModal from './components/ExportCSVModal';
import { HiddenContent } from '../../components/BlurringOverlay';
import { useLazyGetDomainStatisticsQuery } from '../domain-manager/api/domainManagerApiSlice';

const { Title } = Typography;
const { Content } = Layout;

const PAGE_SIZE = 50;

const getResponseMessage = (response, email) => {
  switch (response?.status) {
    case 'queued':
      return {
        content: email
          ? `Your export is processing and will take a couple of minutes. We\'ll email it to you at "${email}" when it\'s ready.`
          : 'We have scheduled your export. You will get a download link in your email once the export is ready.',
        type: 'success',
      };
    case 'not queued':
      return {
        content:
          // eslint-disable-next-line max-len
          "Click start to export all your cached URLs into a CSV. Please note that exporting URLs happens in the background and you'll be notified by email once it's done.",
        type: 'info',
      };
    case 'processing':
      return {
        content: "You already have an export job in progress. We'll inform you via email if that job has finished.",
        type: 'warning',
      };
    case 'already exists':
      return {
        content: 'CSV export has been already requested. Please try again later.',
        type: 'warning',
      };
    case 'limited':
      return {
        content: 'Please upgrade your plan to use this feature.',
        type: 'warning',
      };
    default:
      return {
        content: "Something bad has happened. We're looking into it...",
        type: 'error',
      };
  }
};

const mapToApiPayload = (params) => {
  return {
    domain: params.domain,
    domainId: params.domainId,
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    q: params.filters?.url ? params.filters?.url[0] : undefined,
    adaptive_type: params.filters?.adaptive_type ? params.filters?.adaptive_type[0] : undefined,
    seo_score_status: params.filters?.seo_score_status ? params.filters?.seo_score_status[0] : undefined,
  };
};

const SeoReportPage = () => {
  const { id: domainId } = useParams();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'last_refresh',
      order: 'descend',
    },
  });
  const {
    data: seoReportSummary,
    error: seoReportError,
    isFetching: isFetchingSeoReport,
  } = useGetSeoReportQuery({ domainId });
  const {
    data: seoReportUrls,
    error: seoReportUrlsError,
    isFetching: isFetchingSeoReportUrls,
  } = useGetSeoReportUrlsQuery(
    mapToApiPayload({ ...tableParams, domain: seoReportSummary?.domain, domainId: domainId }),
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [getDomainStatistics, domainStatisticsResult] = useLazyGetDomainStatisticsQuery();
  const [exportCsv, result] = useExportSeoReportCSVMutation();
  const user = usePrerenderUser();
  const [showModal, setShowModal] = useState(false);

  const isSeoReportPageAvailable = useFeatureFlag([SEO_REPORT_FEATURE]);

  const domain = location.state?.domain || seoReportSummary?.domain;

  const totalPages = seoReportSummary
    ? seoReportSummary.good + seoReportSummary.needsImprovement + seoReportSummary.poor
    : 0;

  useEffect(() => {
    if (seoReportSummary?.domain) {
      getDomainStatistics({ domain: seoReportSummary.domain });
    }
  }, [seoReportSummary?.domain]);

  useEffect(() => {
    if (seoReportError) {
      messageApi.error({ content: 'Failed to fetch SEO report summary' });
    }
    if (seoReportUrlsError) {
      messageApi.error({ content: 'Failed to fetch URLs' });
    }
    if (domainStatisticsResult.error) {
      messageApi.error({ content: 'Failed to fetch domain statistics' });
    }
  }, [seoReportError, seoReportUrlsError, domainStatisticsResult.error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((oldParams) => {
      return { ...oldParams, pagination, sorter, filters };
    });
  };

  const debouncedSetTableParams = useCallback(
    debounce((newInput) => {
      setTableParams((oldParams) => ({
        ...oldParams,
        pagination: { ...oldParams.pagination, current: 1 },
        filters: { ...oldParams.filters, url: [newInput] },
      }));
    }, 1500),
    []
  );

  const displayResponseMessage = (message) => {
    messageApi[message.type]({ content: message.content });
  };

  const handleExportCsv = async () => {
    const exportParams = {
      domain: seoReportSummary?.domain,
      sort: tableParams.sorter?.field,
      sortDirection: tableParams.sorter?.order === 'descend' ? 'DESC' : 'ASC',
      q: tableParams.filters?.url ? tableParams.filters?.url[0] : undefined,
      adaptive_type: tableParams.filters?.adaptive_type ? tableParams.filters?.adaptive_type[0] : undefined,
    };

    try {
      const { data: response } = await exportCsv(exportParams);
      const message = getResponseMessage(response, user.email);
      displayResponseMessage(message);
    } catch {
      messageApi.error({ content: 'Failed to export CSV' });
    }
  };

  if (!isSeoReportPageAvailable || !domainId || !Number.isFinite(Number(domainId))) {
    return <Navigate to="/404" />;
  }

  return (
    <AdminTemplate>
      {contextHolder}
      <Content>
        <ExportCSVModal
          onClose={() => setShowModal(false)}
          onExport={async () => {
            await handleExportCsv();
            setShowModal(false);
          }}
          showModal={showModal}
          text="Export SEO Report for this domain as CSV?"
        />
        <Flex vertical gap={24}>
          {!seoReportSummary?.domain || isFetchingSeoReport ? (
            <HiddenContent showLoading={true} loadingText="Calculating...">
              <Flex gap="large">
                <Title level={3}>Seo Report</Title>
              </Flex>
              <Row gutter={[24, 24]}>
                <Col xs={8} xl={7}>
                  <ReportDetailsCard averageScore={0} totalPages={0} />
                </Col>
                <Col xs={24} xl={17}>
                  <Card>
                    <SeoReportChart data={[]} />
                  </Card>
                </Col>
              </Row>
            </HiddenContent>
          ) : (
            <>
              <Flex gap="large">
                <Link to="/domain-manager">
                  <Button icon={<LeftOutlined />} type="link">
                    Back
                  </Button>
                </Link>
                <Title level={3}>Seo Report for {domain}</Title>
              </Flex>
              <Row gutter={[24, 24]}>
                <Col xs={8} xl={7}>
                  <ReportDetailsCard averageScore={seoReportSummary?.averageScore} totalPages={totalPages} />
                </Col>
                <Col xs={24} xl={17}>
                  <Card>
                    <SeoReportChart data={seoReportSummary} />
                  </Card>
                </Col>
              </Row>
            </>
          )}

          <Card>
            <SeoReportUrlsTable
              urls={seoReportUrls?.urls}
              isFetching={isFetchingSeoReportUrls || domainStatisticsResult.isFetching}
              tableParams={tableParams}
              handleTableChange={handleTableChange}
              debouncedSetTableParams={debouncedSetTableParams}
              totalUrls={domainStatisticsResult.data?.data[0]?.number_of_urls}
              exportModalOpen={() => setShowModal(true)}
            />
          </Card>
        </Flex>
      </Content>
    </AdminTemplate>
  );
};

export default requireAuth(SeoReportPage);
