import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SuggestedIntegrationStep from './SuggestedIntegrationStep';
import IntegrationError from './IntegrationError';

function SuggestedIntegrationRouter(props) {
  return (
    <Routes>
      <Route path="failed" element={<IntegrationError />} />
      <Route path="" element={<SuggestedIntegrationStep {...props} />} />
    </Routes>
  );
}

export default SuggestedIntegrationRouter;
