import { Route, Routes } from 'react-router-dom';

import { DomainsListPage, DomainDetailsPage } from '../features/domain-manager';
import requireAuth from '../components/hocs/requireAuth';
import useFeatureFlag, { DOMAIN_SCHEDULER_SETTINGS, DOMAINS_DETAILS_FEATURE } from '../hooks/useFeatureFlag';
import Error404Page from './Error404Page';

function DomainsManagerPage() {
  const canUseDomainDetails = useFeatureFlag([DOMAINS_DETAILS_FEATURE]);
  const canUseSchedulerSettings = useFeatureFlag([DOMAIN_SCHEDULER_SETTINGS]);
  const isDetailsPageAvailable = canUseDomainDetails || canUseSchedulerSettings;

  return (
    <Routes>
      <Route path="" element={<DomainsListPage />} />
      {isDetailsPageAvailable && <Route path=":domain" element={<DomainDetailsPage />} />}
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default requireAuth(DomainsManagerPage);
